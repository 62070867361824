import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="psionic-feats"></a><h2>Psionic Feats</h2>
    <h2 style={{
      "marginTop": "-40px",
      "marginBottom": "0px"
    }}><span className="subtitle">In
Alphabetical Order</span></h2>
    <table className="monster-index" border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr valign="top">
          <td style={{
            "width": "12%"
          }}><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#aligned-attack">Aligned Attack</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#antipsionic-magic">Antipsionic Magic</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#autonomous">Autonomous</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#body-fuel">Body
Fuel</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#boost-construct">Boost
Construct</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#burrowing-power">Burrowing
Power</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#chain-power">Chain
Power</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#chaotic-mind">Chaotic
Mind</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#cloak-dance">Cloak
Dance</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#closed-mind">Closed
Mind</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#combat-manifestation">Combat Manifestation</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#craft-cognizance-crystal">Craft Cognizance Crystal</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#craft-dorje">Craft
Dorje</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#craft-psicrown">Craft
Psicrown</a>
          </td>
          <td style={{
            "width": "12%"
          }}><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#craft-psionic-arms-and-armor">Craft Psionic Arms and 
            </a>
            <div style={{
              "marginLeft": "40px"
            }}><a style={{
                "color": "rgb(87, 158, 182)"
              }} href="#craft-psionic-arms-and-armor">Armor</a>
            </div>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#craft-psionic-construct">Craft Psionic Construct</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#craft-universal-item">Craft Universal Item</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#deadly-precision">Deadly
Precision</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#deep-impact">Deep
Impact</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#delay-power">Delay
Power</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#empower-power">Empower
Power</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#enlarge-power">Enlarge
Power</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#expanded-knowledge">Expanded Knowledge</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#extend-power">Extend
Power</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#fell-shot">Fell
Shot</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#focused-sunder">Focused
Sunder</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#force-of-will">Force
of Will</a></td>
          <td style={{
            "width": "12%"
          }}><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#ghost-attack">Ghost Attack</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#greater-manyshot">Greater Manyshot</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#greater-power-penetration">Greater Power Penetration</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#greater-power-penetration">Greater Power 
            </a>
            <div style={{
              "marginLeft": "40px"
            }}><a style={{
                "color": "rgb(87, 158, 182)"
              }} href="#greater-power-penetration">Specialization</a>
            </div>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#greater-psionic-endowment">Greater Psionic Endowment</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#greater-psionic-fist">Greater Psionic Fist</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#greater-psionic-shot">Greater Psionic Shot</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#greater-psionic-weapon">Greater Psionic Weapon</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#hostile-mind">Hostile
Mind</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#imprint-stone">Imprint
Stone</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#improved-psicrystal">Improved Psicrystal</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#inquisitor">Inquisitor</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#maximize-power">Maximize
Power</a></td>
          <td style={{
            "width": "12%"
          }}><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#mental-leap">Mental Leap</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#mental-resistance">Mental Resistance</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#metamorphic-transfer">Metamorphic Transfer</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#mind-over-body">Mind
Over Body</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#narrow-mind">Narrow
Mind</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#open-minded">Open
Minded</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#opportunity-power">Opportunity Power</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#overchannel">Overchannel</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#power-penetration">Power Penetration</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#power-specialization">Power Specialization</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#psicrystal-affinity">Psicrystal Affinity</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#psicrystal-containment">Psicrystal Containment</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#psionic-affinity">Psionic Affinity</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#psionic-body">Psionic
Body</a></td>
          <td style={{
            "width": "12%"
          }}><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#psionic-charge">Psionic Charge</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#psionic-dodge">Psionic
Dodge</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#psionic-endowment">Psionic Endowment</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#psionic-fist">Psionic
Fist</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#psionic-hole">Psionic
Hole</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#psionic-meditation">Psionic Meditation</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#psionic-shot">Psionic
Shot</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#psionic-talent">Psionic
Talent</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#psionic-weapon">Psionic
Weapon</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#quicken-power">Quicken
Power</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#rapid-metabolism">Rapid Metabolism</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#reckless-offense">Reckless Offense</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#return-shot">Return
Shot</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#scribe-tattoo">Scribe
Tattoo</a></td>
          <td style={{
            "width": "12%"
          }}><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#sidestep-charge">Sidestep Charge</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#speed-of-thought">Speed of Thought</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#split-psionic-ray">Split Psionic Ray</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#stand-still">Stand
Still</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#talented">Talented</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#twin-power">Twin
Power</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#unavoidable-strike">Unavoidable Strike</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#unconditional-power">Unconditional Power</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#up-the-walls">Up
the Walls</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#widen-power">Widen
Power</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#wild-talent">Wild
Talent</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#wounding-attack">Wounding
Attack</a></td>
        </tr>
      </tbody>
    </table>
    <p>Psionic feats are available only to characters and creatures
with the ability to manifest powers. (In other words, they either have
a power point reserve or have psi-like abilities.)</p>
    <p>Because psionic feats are supernatural abilities - a departure
from the general rule that feats do not grant supernatural
abilities - they cannot be disrupted in combat (as powers can be) and
generally do not provoke attacks of opportunity (except as noted in
their descriptions). Supernatural abilities are not subject to power
resistance and cannot be dispelled; however, they do not function in
areas where psionics is suppressed, such as a <a href="psionicPowersGtoP.html#null-psionics-field" style={{
        "color": "rgb(87, 158, 182)"
      }}>null psionics field</a>.
Leaving such an area immediately allows psionic feats to be used.</p>
    <p>Many psionic feats can be used only when you are psionically
focused; others require you to expend your psionic focus to gain their
benefit. Expending your psionic focus does not require an action; it is
part of another action (such as using a feat). When you expend your
psionic focus, it applies only to the action for which you expended it.</p>
    <a id="psionic-item-creation-feats"></a><h5>PSIONIC ITEM CREATION FEATS</h5>
Manifesters can use their personal power to create lasting psionic
items. Doing so, however, is draining. A manifester must put a little
of himself or herself into every psionic item he or she creates.
    <p>A psionic item creation feat lets a manifester create a
psionic item of a certain type. Regardless of the type of items they
involve, the various item creation feats all have certain features in
common.</p>
    <a id="xp-cost"></a><p><span style={{
        "fontWeight": "bold"
      }}>XP Cost:</span> Power and
energy that the manifester would normally keep is expended when making
a psionic item. The experience point cost of using a psionic item
creation feat equals 1/25 the cost of the item in gold pieces. A
character cannot spend so much XP on an item that he or she loses a
level. However, upon gaining enough XP to attain a new level, he or she
can immediately expend XP on creating an item rather than keeping the
XP to advance a level.</p>
    <a id="raw-materials"></a><p><span style={{
        "fontWeight": "bold"
      }}>Raw Materials Cost:</span>
Creating a psionic item requires costly components, most of which are
consumed in the process. The cost of these materials equals 1/2 the
cost of the item.</p>
    <p>Using a psionic item creation feat also requires access to a
laboratory or psionic workshop, special tools, and other equipment. A
character generally has access to what he or she needs unless unusual
circumstances apply (such as if he&rsquo;s traveling far from home).</p>
    <a id="time"></a><p><span style={{
        "fontWeight": "bold"
      }}>Time:</span> The time to
create a psionic item depends on the feat and the cost of the item. The
minimum time is one day.</p>
    <a id="item-cost"></a><p><span style={{
        "fontWeight": "bold"
      }}>Item Cost:</span> Craft
Dorje, Imprint Stone, and Scribe Tattoo create items that directly
reproduce the effects of powers, and the strength of these items
depends on their manifester level-that is, a power from such an item
has the strength it would have if manifested by a manifester of that
level. Often, that is the minimum manifester level necessary to
manifest the power. (Randomly discovered items usually follow this
rule.) However, when making such an item, the item&rsquo;s strength can
be
set higher than the minimum. Any time a character creates an item using
a power augmented by spending additional power points, the
character&rsquo;s
effective manifester level for the purpose of calculating the
item&rsquo;s
cost increases by 1 for each 1 additional power point spent.
(Augmentation is a feature of many powers that allows the power to be
amplified in various ways if additional power points are spent.) All
other level-dependent parameters of the power forged into the item are
set according to the effective manifester level.</p>
    <p>The price of psionic items (and thus the XP cost and the cost
of the raw materials) depends on the level of the power and a
character&rsquo;s manifester level. The character&rsquo;s manifester
level must be
high enough that the item creator can manifest the power at the chosen
level. To find the final price in each case, multiply the
character&rsquo;s
manifester level by the power level, then multiply the result by a
constant, as shown below.</p>
    <a id="power-stones-cost"></a><p><span style={{
        "fontStyle": "italic"
      }}>Power Stones:</span> Base
price = power level x manifester level x 25 gp</p>
    <a id="psionic-tattoos-cost"></a><p><span style={{
        "fontStyle": "italic"
      }}>Psionic Tattoos:</span> Base
price = power level x manifester level x 50 gp</p>
    <a id="dorjes-cost"></a><p><span style={{
        "fontStyle": "italic"
      }}>Dorjes:</span> Base price =
power level x manifester level x 750 gp</p>
    <a id="extra-costs"></a><p><span style={{
        "fontWeight": "bold"
      }}>Extra Costs:</span> Any
dorje, power stone, or psionic tattoo that stores a power with an XP
cost also carries a commensurate cost. </p>
    <p>For psionic tattoos and power stones, the creator must pay the
XP cost when creating the item. For a dorje, the creator must pay fifty
times the XP cost. </p>
    <p>Some psionic items similarly incur extra costs in XP, as noted
in their descriptions.</p>
    <a id="metapsionic-feats"></a><h5>METAPSIONIC FEATS</h5>
As a manifester&rsquo;s knowledge of psionics grows, he can learn to
manifest
powers in ways slightly different from how the powers were originally
designed or learned. Of course, manifesting a power while using a
metapsionic feat is more expensive than manifesting the power normally.
    <a id="manifesting-time"></a><p><span style={{
        "fontWeight": "bold"
      }}>Manifesting Time:</span>
Powers manifested using metapsionic feats take the same time as
manifesting the powers normally unless the feat description
specifically says otherwise.</p>
    <a id="manifestation-cost"></a><p><span style={{
        "fontWeight": "bold"
      }}>Manifestation Cost:</span> To
use a metapsionic feat, a psionic character must both expend his
psionic focus (see the <a href="psionicSkills.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a> skill description)
and pay an
increased power point cost as given in the feat description.</p>
    <a id="limits-on-use"></a><p><span style={{
        "fontWeight": "bold"
      }}>Limits on Use:</span> As with
all powers, you cannot spend more power points on a power than your
manifester level. Metapsionic feats merely let you manifest powers in
different ways; they do not let you violate this rule. </p>
    <a id="effects-of-metapsionics-feats-on-a-power"></a><p><span style={{
        "fontWeight": "bold"
      }}>Effects of Metapsionic Feats
on a Power:</span> In all ways, a metapsionic power operates at its
original power level, even though it costs additional power points. The
modifications to a power made by a metapsionic feat have only their
noted effect on the power. A manifester can&rsquo;t use a metapsionic
feat to
alter a power being cast from a power stone, dorje, or other device.</p>
    <p>Manifesting a power modified by the <a href="#quicken-power" style={{
        "color": "rgb(87, 158, 182)"
      }}>Quicken Power</a> feat does
not provoke attacks of opportunity.</p>
    <p>Some metapsionic feats apply only to certain powers, as
described in each specific feat entry.</p>
    <a id="psionic-items-and-metapsionic-powers"></a><p><span style={{
        "fontWeight": "bold"
      }}>Psionic Items and Metapsionic
Powers:</span> With the right psionic item creation feat, you can store
a metapsionic power in a power stone, psionic tattoo, or dorje. Level
limits for psionic tattoos apply to the power&rsquo;s higher
metapsionic
level. </p>
    <p>A character doesn&rsquo;t need the appropriate metapsionic
feat to
activate an item in which a metapsionic power is stored, but does need
the metapsionic feat to create such an item.</p>
    <a id="feat-descriptions"></a><h3>FEAT DESCRIPTIONS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <p>{`These feat descriptions follow the standard format.`}</p>
    <h5><a id="aligned-attack"></a>ALIGNED ATTACK [PSIONIC]</h5>
Your melee or ranged attack overcomes your opponent&rsquo;s
alignment-based <a href="monsterTypes.html#DR" style={{
      "color": "rgb(87, 158, 182)"
    }}>damage
reduction</a> and deals additional damage.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span> Base
attack bonus +6.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> When you take
this feat, choose either chaos, good, evil or law. (Your choice must
match one of your alignment components. Once you&rsquo;ve made this
alignment
choice, it cannot be changed.</p>
    <p>To use this feat, you must expend your psionic focus. When you
make a successful melee or ranged attack, you deal an extra 1d6 points
of damage, and your attack is treated as either a good, evil, chaotic,
or lawful attack (depending on your original choice) for the purpose of
overcoming damage reduction.</p>
    <p>You must decide whether or not to use this feat prior to
making an attack. If your attack misses, you still expend your psionic
focus.</p>
    <h5><a id="antipsionic-magic"></a>ANTIPSIONIC MAGIC [GENERAL]</h5>
Your spells are more potent when used against psionic characters and
creatures.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span> <a href="skillsAll.html#spellcraft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spellcraft</a>
5 ranks.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> You get a get
a +2 bonus on caster level checks made to overcome a psionic
creature&rsquo;s
power resistance.</p>
    <p>This bonus stacks with the bonus conferred by <a href="featsAll.html#spell-penetration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spell
Penetration</a> and <a href="featsAll.html#greater-spell-penetration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Greater Spell Penetration</a>.
Moreover, whenever a psionic
creature attempts to dispel a spell you cast, it makes its manifester
level check against a DC of 13 + its caster level.</p>
    <p>The benefits of this feat apply only to power resistance. </p>
    <p>The bonus does not apply to spell resistance. This is an
exception to the psionics-magic transparency rule.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Special:</span> You cannot
take or use this feat if you have the ability to use powers (if you
have a power point reserve or psi-like abilities).</p>
    <h5><a id="autonomous"></a>AUTONOMOUS [GENERAL]</h5>
You have a knack for psionic self-sufficiency.
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> You get a +2
bonus on all <a href="psionicSkills.html#autohypnosis" style={{
        "color": "rgb(87, 158, 182)"
      }}>Autohypnosis</a> checks and <a href="psionicSkills.html#knowledge-psionics" style={{
        "color": "rgb(87, 158, 182)"
      }}>Knowledge (psionics)</a> checks.</p>
    <h5><a id="body-fuel"></a>BODY FUEL [PSIONIC]</h5>
You can expand your power point total at the expense of your health.
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> You can
recover 2 power points by taking 1 point of ability burn damage to each
of your three ability scores: Strength, Dexterity, and Constitution.</p>
    <p>You can recover additional power points for a proportional
cost to Strength, Dexterity, and Constitution. These recovered points
are added to your power point reserve as if you had gained them by
resting overnight.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Special:</span> Only living
creatures can use this feat. You can take advantage of this feat only
while in your own body.</p>
    <h5><a id="boost-construct"></a>BOOST CONSTRUCT [PSIONIC]</h5>
Your astral constructs have more abilities.
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> When you
create an <a href="psionicPowersAtoC.html#astral-construct" style={{
        "color": "rgb(87, 158, 182)"
      }}>astral construct</a>, you can give
it one additional special
ability from any menu that the construct currently has an ability from.</p>
    <h5><a id="burrowing-power"></a>BURROWING POWER [METAPSIONIC]</h5>
Your powers sometimes bypass barriers.
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> To use this
feat, you must expend your psionic focus. You can attempt to manifest
your powers against targets that are sheltered behind a <a href="spellsTtoZ.html#wall-of-force" style={{
        "color": "rgb(87, 158, 182)"
      }}>wall
or force</a>
effect. Your power briefly skips through the Astral Plane to bypass the
barrier.</p>
    <p>The strength and thickness of the barrier determine your
chance of success. To successfully bypass the barrier with your power,
you make a <a href="psionicSkills.html#psicraft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Psicraft</a> check against a DC
equal to 10 + the hardness of
the barrier + 1 per foot of thickness (minimum 1). Assign a hardness of
20 to barriers without a hardness rating, such as force effects (or a <a href="psionicPowersQtoW.html#wall-of-ectoplasm" style={{
        "color": "rgb(87, 158, 182)"
      }}>wall of ectoplasm</a>). Force walls
or walls of ectoplasm are assumed
to
have less than 1 foot of thickness unless noted otherwise.</p>
    <p>If a power requires line of sight (which includes most powers
that affect a target or targets instead of an area), you cannot
manifest it as a burrowing power unless you can somehow see the target,
such as with <a href="psionicPowersAtoC.html#clairvoyant-sense" style={{
        "color": "rgb(87, 158, 182)"
      }}>clairvoyant sense</a>. Using this
feat increases the power
point cost of the power by 2. The power&rsquo;s total cost cannot
exceed your
manifester level.</p>
    <h5><a id="chain-power"></a>CHAIN POWER [METAPSIONIC]</h5>
You can manifest powers that arc to hit other targets in addition to
the primary target. 
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> To use this
feat, you must expend your psionic focus. You can chain any power that
affects a single target and that deals either acid, cold, electricity,
fire, or sonic damage. After the primary target is struck, the power
can arc to a number of secondary targets equal to your manifester level
(maximum twenty). The secondary arcs each strike one target and deal
half as much damage as the primary one did (round down).</p>
    <p>Each target gets to make a saving throw, if one is allowed by
the power. You choose secondary targets as you like, but they must all
be within 30 feet of the primary target, and no target can be struck
more than once. You can choose to affect fewer secondary targets than
the maximum (to avoid allies in the area, for example).</p>
    <p>Using this feat increases the power point cost of the power by
6. The power&rsquo;s total cost cannot exceed your manifester level.</p>
    <h5><a id="chaotic-mind"></a>CHAOTIC MIND [GENERAL]</h5>
The turbulence of your thoughts prevents others from gaining insight
into your actions.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span> Chaotic
alignment, Cha 15.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> Creatures and
characters who have an insight bonus on their attack rolls, an insight
bonus to their Armor Class, or an insight bonus on skill checks or
ability checks do not gain those bonuses against you.</p>
    <p>The benefit of this feat applies only to insight bonuses
gained from psionic powers and psi-like abilities. This is an exception
to the psionics-magic transparency rule.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Special:</span> You cannot
take or use this feat if you have the ability to use powers (if you
have a power point reserve or psi-like abilities).</p>
    <h5><a id="cloak-dance"></a>CLOAK DANCE [GENERAL]</h5>
You are skilled at using optical tricks to make yourself seem to be
where you are not.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisites:</span> <a href="skillsAll.html#hide" style={{
        "color": "rgb(87, 158, 182)"
      }}>Hide</a>
10
ranks, <a href="skillsAll.html#perform" style={{
        "color": "rgb(87, 158, 182)"
      }}>Perform</a> (dance) 2 ranks.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> You can take
a move action to obscure your exact position. Until your next turn, you
have concealment. Alternatively, you can take a full-round action to
entirely obscure your exact position. Until your next action, you have
total concealment.</p>
    <h5><a id="closed-mind"></a>CLOSED MIND [GENERAL]</h5>
Your mind is better able to resist psionics than normal.
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> You get a +2
bonus on all saving throws to resist powers.</p>
    <p>The benefit of this feat applies only to psionic powers and
psi-like abilities. This is an exception to the psionics-magic
transparency rule.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Special:</span> You cannot
take or use this feat if you have the ability to use powers (if you
have a power point reserve or psi-like abilities).</p>
    <h5><a id="combat-manifestation"></a>COMBAT MANIFESTATION
[PSIONIC]</h5>
You are adept at manifesting powers in combat.
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> You get a +4
bonus on <a href="psionicSkills.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a> checks made to
manifest a power or use a
psi-like ability while on the defensive or while you are <a href="abilitiesAndConditions.html#grappling" style={{
        "color": "rgb(87, 158, 182)"
      }}>grappling</a> or <a href="abilitiesAndConditions.html#pinned" style={{
        "color": "rgb(87, 158, 182)"
      }}>pinned</a>.</p>
    <h5><a id="craft-cognizance-crystal"></a>CRAFT COGNIZANCE
CRYSTAL [ITEM CREATION]</h5>
You can create psionic cognizance crystals that store power points.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span>
Manifester level 3rd.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> You can
create a cognizance crystal. Doing so takes one day for each 1,000 gp
in its base price. The base price of a cognizance crystal is equal to
the highest-level power it could manifest using all its stored power
points, squared, multiplied by 1,000 gp. To create a cognizance
crystal, you must spend 1/25 of its base price in XP and use up raw
materials costing one-half its base price.</p>
    <h5><a id="craft-dorje"></a>CRAFT DORJE [ITEM CREATION]</h5>
You can create slender crystal wands called dorjes than manifest powers
when charges are expended.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span>
Manifester level 5th.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> You can
create a dorje of any psionic power you know (barring exceptions, such
as bestow power, as noted in a power&rsquo;s description). Crafting a
dorje
takes one day for each 1,000 gp in its base price. The base price of a
dorje is its manifester level x the power level x 750 gp. To craft a
dorje, you must spend 1/25 of this base price in XP and use up raw
materials costing one-half of this base price.</p>
    <p>A newly created dorje has 50 charges.</p>
    <p>Any dorje that stores a power with an XP cost also carries a
commensurate cost. In addition to the XP cost derived from the base
price, you must pay fifty times the XP cost.</p>
    <h5><a id="craft-psicrown"></a>CRAFT PSICROWN [ITEM CREATION]</h5>
You can create psicrowns, which have multiple psionic effects.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span>
Manifester level 12th.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> You can
create any psicrown whose prerequisites you meet. Crafting a psicrown
takes one day for each 1,000 gp in its base price. To craft a psicrown,
you must spend 1/25 of its base price in XP and use up raw materials
costing one-half of its base price. Some psicrowns incur extra costs in
XP as noted in their descriptions. These costs are in addition to those
derived from the psicrown&rsquo;s base price.</p>
    <h5><a id="craft-psionic-arms-and-armor"></a>CRAFT PSIONIC ARMS
AND ARMOR [ITEM CREATION]</h5>
You can create psionic weapons, armor, and shields.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span>
Manifester level 5th.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> You can
create any psionic weapon, armor, or shield whose prerequisites you
meet. Enhancing a weapon, suit of armor, or shield takes one day for
each 1,000 gp in the price of its psionic features. To enhance a
weapon, you must spend 1/25 of its features&rsquo; total price in XP
and use
up raw materials costing one-half of this total price.</p>
    <p>The weapon, armor, or shield to be enhanced must be a
masterwork item that you provide. Its cost is not included in the above
cost.</p>
    <p>You can also mend a broken psionic weapon, suit of armor, or
shield if it is one that you could make. Doing so costs half the XP,
half the raw materials, and half the time it would take to enhance that
item in the first place.</p>
    <h5><a id="craft-psionic-construct"></a>CRAFT PSIONIC CONSTRUCT
[ITEM CREATION]</h5>
You can create golems and other psionic automatons that obey your
orders.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisites:</span> <a href="#craft-psionic-arms-and-armor" style={{
        "color": "rgb(87, 158, 182)"
      }}>Craft
Psionic Arms and Armor</a>, <a href="#craft-universal-item" style={{
        "color": "rgb(87, 158, 182)"
      }}>Craft Universal Item</a>.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> You can
create any psionic construct whose prerequisites you meet. Creating a
construct takes one day for each 1,000 gp in its base price. To create
a construct, you must spend 1/25 of the construct&rsquo;s base price in
XP
and use up raw materials costing one-half of this price. A newly
created construct has average hit points for its Hit Dice.</p>
    <h5><a id="craft-universal-item"></a>CRAFT UNIVERSAL ITEM [ITEM
CREATION]</h5>
You can create universal psionic items.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span>
Manifester level 3rd.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> You can
create any universal psionic item whose prerequisites you meet.
Crafting a universal psionic item takes one day for each 1,000 gp in
its base price. To craft a universal psionic item, you must spend 1/25
of the item&rsquo;s base price in XP and use up raw materials costing
one-half of this price.</p>
    <p>You can also mend a broken universal item if it is one that
you could make. Doing so costs half the XP, half the raw materials, and
half the time it would take to craft that item in the first place.</p>
    <p>Some universal items incur extra costs in XP, as noted in
their descriptions. These costs are in addition to those derived from
the item&rsquo;s base price. You must pay such a cost to create an item
or to
mend a broken one.</p>
    <h5><a id="deadly-precision"></a>DEADLY PRECISION [GENERAL]</h5>
You empty your mind of all distracting emotion, becoming an instrument
of deadly precision.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span> Dex 15,
base attack bonus +5.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> You have
deadly accuracy with your sneak attacks. You can reroll any result of 1
on your sneak attack&rsquo;s extra damage dice. You must keep the
result of
the reroll, even if it is another 1.</p>
    <h5><a id="deep-impact"></a>DEEP IMPACT [PSIONIC]</h5>
You can strike your foe with a melee weapon as if making a touch attack.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span> Str 13, <a href="#psionic-weapon" style={{
        "color": "rgb(87, 158, 182)"
      }}>Psionic
Weapon</a>, base attack bonus +5.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> To use this
feat, you must expend your psionic focus. You can resolve your attack
with a melee weapon as a touch attack. You must decide whether or not
to use this feat prior to making an attack. If your attack misses, you
still expend your psionic focus.</p>
    <h5><a id="delay-power"></a>DELAY POWER [METAPSIONIC]</h5>
You can manifest powers that go off up to 5 rounds later.
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> To use this
feat, you must expend your psionic focus. You can manifest a power as a
delayed power. A delayed power doesn&rsquo;t activate immediately. When
you
manifest the power, you choose one of three trigger mechanisms: (1) The
power activates when you take a standard action to activate it; (2) It
activates when a creature enters the area that the power will affect
(only powers that affect areas can use this trigger condition); or (3)
It activates on your turn after 5 rounds pass. If you choose one of the
first two triggers and the conditions are not met within 5 rounds, the
power activates automatically on the fifth round.</p>
    <p>Only area and personal powers can be delayed.</p>
    <p>Any decisions you would make about the delayed power,
including attack rolls, designating targets, or determining or shaping
an area, are decided when the power is manifested. Any effects resolved
by those affected by the power, including saving throws, are decided
when the delay period ends.</p>
    <p>A delayed power can be dispelled normally during the delay,
and can be detected normally in the area or on the target by the use of
powers that can detect psionic effects. </p>
    <p>Using this feat increases the power point cost of the power by
2. The power&rsquo;s total cost cannot exceed your manifester level.</p>
    <h5><a id="empower-power"></a>EMPOWER POWER [METAPSIONIC]</h5>
You can manifest powers to greater effect.
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> To use this
feat, you must expend your psionic focus.</p>
    <p>You can empower a power. All variable, numeric effects of an
empowered power are increased by one-half. An empowered power deals
half again as much damage as normal, cures half again as many hit
points, affects half again as many targets, and so forth, as
appropriate. Augmented powers can also be empowered (multiply 1-1/2
times the damage total of the augmented power). Saving throws and
opposed checks (such as the one you make when you manifest <a href="psionicPowersDtoF.html#dispel-psionics" style={{
        "color": "rgb(87, 158, 182)"
      }}>dispel
psionics</a>) are not affected, nor are powers without random variables.</p>
    <p>Using this feat increases the power point cost of the power by
2. The power&rsquo;s total cost cannot exceed your manifester level.</p>
    <h5><a id="enlarge-power"></a>ENLARGE POWER [METAPSIONIC]</h5>
You can manifest powers farther than normal.
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> To use this
feat, you must expend your psionic focus. You can alter a power with a
range of close, medium, or long to increase its range by 100%. An
enlarged power with a range of close has a range of 50 feet + 5 feet
per level, a medium-range power has a range of 200 feet + 20 feet per
level, and a long-range power has a range of 800 feet + 80 feet per
level.</p>
    <p>Powers whose ranges are not defined by distance, as well as
powers whose ranges are not close, medium, or long, are not affected.</p>
    <p>Using this feat does not increase the power point cost of the
power.</p>
    <h5><a id="expanded-knowledge"></a>EXPANDED KNOWLEDGE [PSIONIC]</h5>
You learn another power.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisites:</span>
Manifester level 3rd.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> Add to your
powers known one additional power of any level up to one level lower
than the highest-level power you can manifest. You can choose any
power, including powers from another discipline&rsquo;s list or even
from
another class&rsquo;s list.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Special:</span> You can gain
this feat multiple times. Each time, you learn one new power at any
level up to one less than the highest-level power you can manifest.</p>
    <h5><a id="extend-power"></a>EXTEND POWER [METAPSIONIC]</h5>
You can manifest powers that last longer than normal.
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> To use this
feat, you must expend your psionic focus.</p>
    <p>You can manifest an extended power. An extended power lasts
twice as long as normal. A power with a duration of concentration,
instantaneous, or permanent is not affected by this feat.</p>
    <p>Using this feat increases the power point cost of the power by
2. The power&rsquo;s total cost cannot exceed your manifester level.</p>
    <h5><a id="fell-shot"></a>FELL SHOT [PSIONIC]</h5>
You can strike your foe with a ranged weapon as if making a touch
attack.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span> Dex 13, <a href="featsAll.html#point-blank-shot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Point
Blank Shot</a>, <a href="#psionic-shot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Psionic Shot</a>,
base attack bonus +5.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> To use this
feat, you must expend your psionic focus. You can resolve your ranged
attack as a ranged touch attack.</p>
    <p>You must decide whether or not to use this feat prior to
making an attack. If your attack misses, you still expend your psionic
focus.</p>
    <h5><a id="focused-sunder"></a>FOCUSED SUNDER [PSIONIC]</h5>
You can sense the stress points on others&rsquo; weapons.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span> Str 13, <a href="featsAll.html#power-attack" style={{
        "color": "rgb(87, 158, 182)"
      }}>Power
Attack</a>, <a href="featsAll.html#improved-sunder" style={{
        "color": "rgb(87, 158, 182)"
      }}>Improved
Sunder</a>.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> To use this
feat, you must expend your psionic focus.</p>
    <p>When you strike at an opponent&rsquo;s weapon, you ignore half
of
the weapon&rsquo;s total hardness (round down). Total hardness includes
any
magical or psionic enhancements possessed by the weapon that increase
its hardness.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Special:</span> You can also
sense the stress points in any hard construction, such as wooden doors
or stone walls, and can ignore half of the object&rsquo;s total
hardness
(round down) when attacking that object.</p>
    <h5><a id="force-of-will"></a>FORCE OF WILL [GENERAL]</h5>
You are able to resist psionic attacks with extreme force of will.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span> <a href="featsAll.html#iron-will" style={{
        "color": "rgb(87, 158, 182)"
      }}>Iron
Will</a>.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> Once per
round, when targeted by a psionic effect that allows a Reflex save or a
Fortitude save, you can instead make a Will saving throw to avoid the
effect.</p>
    <p>The benefit of this feat applies only to psionic powers and
psi-like abilities. This is an exception to the psionics-magic
transparency rule.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Special:</span> You cannot
take or use this feat if you have the ability to use powers (if you
have a power point reserve or psi-like abilities).</p>
    <h5><a id="ghost-attack"></a>GHOST ATTACK [PSIONIC]</h5>
Your deadly strikes against incorporeal foes always find their mark.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span> Base
attack bonus +3.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> You must be
psionically focused to use this feat. When you make a melee attack or a
ranged attack against an incorporeal creature, you can make two rolls
to check for the miss chance. If either is successful, the attack is
treated as if it were made with a ghost touch weapon for the purpose of
affecting the creature. Your weapon or natural weapon actually appears
to become briefly incorporeal as the attack is made.</p>
    <h5><a id="greater-manyshot"></a>GREATER MANYSHOT [GENERAL]</h5>
You are skilled at firing many arrows at once, even at different
opponents.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisites:</span> Dex 17,
      <a href="featsAll.html#manyshot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Manyshot</a>,
      <a href="featsAll.html#point-blank-shot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Point Blank Shot</a>, <a href="featsAll.html#rapid-shot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Rapid
Shot</a>, base attack bonus +6.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> When you use
the Manyshot feat, you can fire each arrow at a different target
instead of firing all of them at the same target. You make a separate
attack roll for each arrow, regardless of whether you fire them at
separate targets or the same target. Your precision-based damage
applies to each arrow fired, and, if you score a critical hit with more
than one of the arrows, each critical hit deals critical damage.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Special:</span> A fighter may
select this feat as one of his fighter bonus feats.</p>
    <h5><a id="greater-power-penetration"></a>GREATER POWER
PENETRATION [PSIONIC]</h5>
Your powers are especially potent at breaking through power resistance.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span> <a href="#power-penetration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Power
Penetration</a>.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> To use this
feat, you must expend your psionic focus. You get a +8 bonus on
manifester level checks to overcome a creature&rsquo;s power
resistance. This
bonus overlaps with the bonus from <a href="#power-penetration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Power Penetration</a>.</p>
    <h5><a id="greater-power-specialization"></a>GREATER POWER
SPECIALIZATION [PSIONIC]</h5>
You deal more damage with your powers.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisites:</span> <a href="#power-specialization" style={{
        "color": "rgb(87, 158, 182)"
      }}>Power
Specialization</a>, <a href="featsAll.html#weapon-focus" style={{
        "color": "rgb(87, 158, 182)"
      }}>Weapon Focus</a> (ray), manifester
level 12th.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> Your powers
that deal damage deal an extra 2 points of damage. This damage stacks
with other bonuses on damage rolls to powers, including the one from
Power Specialization. The damage bonus applies only if the target or
targets are within 30 feet.</p>
    <h5><a id="greater-psionic-endowment"></a>GREATER PSIONIC
ENDOWMENT [PSIONIC]</h5>
You can use meditation to focus your powers.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span> <a href="#psionic-endowment" style={{
        "color": "rgb(87, 158, 182)"
      }}>Psionic
Endowment</a>.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> When you use
the Psionic Endowment feat, you add +2 to the save DC of a power you
manifest instead of +1.</p>
    <h5><a id="greater-psionic-fist"></a>GREATER PSIONIC FIST
[PSIONIC]</h5>
You can charge your unarmed strike or natural weapon with additional
damage potential.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span> Str 13, <a href="#psionic-fist" style={{
        "color": "rgb(87, 158, 182)"
      }}>Psionic
Fist</a>, base attack bonus +5.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> When you use
the Psionic Fist feat, your unarmed attack or attack with a natural
weapon deals an extra 4d6 points of damage instead of an extra 2d6
points.</p>
    <h5><a id="greater-psionic-shot"></a>GREATER PSIONIC SHOT
[PSIONIC]</h5>
You can charge your ranged attacks with additional damage potential.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span> <a href="featsAll.html#point-blank-shot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Point
Blank Shot</a>, <a href="#psionic-shot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Psionic Shot</a>, base attack bonus
+5.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> When you use
the Psionic Shot feat, your ranged attack deals an extra 4d6 points of
damage instead of an extra 2d6 points.</p>
    <h5><a id="greater-psionic-weapon"></a>GREATER PSIONIC WEAPON
[PSIONIC]</h5>
You can charge your melee weapon with additional damage potential.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span> Str 13, <a href="#psionic-weapon" style={{
        "color": "rgb(87, 158, 182)"
      }}>Psionic
Weapon</a>, base attack bonus +5.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> When you use
the Psionic Weapon feat, your attack with a melee weapon deals an extra
4d6 points of damage instead of an extra 2d6 points.</p>
    <h5><a id="hostile-mind"></a>HOSTILE MIND [GENERAL]</h5>
Your mind recoils violently against those who use psionics against you.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span> Cha 15.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> Whenever you
are subject to a power from the telepathy discipline (regardless of
whether the power is harmful or beneficial to you), the manifester must
make a Will saving throw against a DC of 10 + 1/2 your character level
+ your Charisma bonus or take 2d6 points of damage.</p>
    <p>The benefit of this feat applies only to psionic powers and
psi-like abilities. This is an exception to the psionics-magic
transparency rule.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Special:</span> You cannot
take or use this feat if you have the ability to use powers (if you
have a power point reserve or psi-like abilities).</p>
    <h5><a id="imprint-stone"></a>IMPRINT STONE [ITEM CREATION]</h5>
You can create power stones to store psionic powers.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span>
Manifester level 1st.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> You can
create a power stone of any power that you know. Encoding a power stone
takes one day for each 1,000 gp in its base price. The base price of a
power stone is the level of the stored power x its manifester level x
25 gp. To imprint a power stone, you must spend 1/25 of this base price
in XP and use up raw materials costing one-half of this base price.</p>
    <p>Any power stone that stores a power with an XP cost also
carries a commensurate cost. In addition to the costs derived from the
base price, you must pay the XP when encoding the stone.</p>
    <h5><a id="improved-psicrystal"></a>IMPROVED PSICRYSTAL
[PSIONIC]</h5>
You can upgrade your psicrystal.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisites:</span> <a href="#psicrystal-affinity" style={{
        "color": "rgb(87, 158, 182)"
      }}>Psicrystal
Affinity</a>.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> You can
implant another personality fragment in your <a style={{
        "color": "#579eb6"
      }} href="psionicPsion.html#psicrystals">psicrystal</a>. You gain the
benefits of both psicrystal personalities. Your psicrystal&rsquo;s
personality adjusts and becomes a blend between all implanted
personality fragments. From now on, when determining the abilities of
your psicrystal, treat your manifester level as one higher than your
normal manifester level.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Special:</span> You can gain
this feat multiple times. Each time, you implant a new personality
fragment in your psicrystal, from which you derive the noted benefits,
and you treat your level as one higher for the purpose of determining
your psicrystal&rsquo;s abilities.</p>
    <h5><a id="inquisitor"></a>INQUISITOR [PSIONIC] </h5>
You know when others lie.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span> Wis 13.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> To use this
feat, you must expend your psionic focus.</p>
    <p>You gain a +10 bonus on a <a href="skillsAll.html#sense-motive" style={{
        "color": "rgb(87, 158, 182)"
      }}>Sense
Motive</a> check to oppose a <a href="skillsAll.html#bluff" style={{
        "color": "rgb(87, 158, 182)"
      }}>Bluff</a>
check.</p>
    <p>You must decide whether or not to use this feat prior to
making a Sense Motive check. If your check fails, or if the opponent
isn&rsquo;t lying, you still expend your psionic focus. </p>
    <h5><a id="maximize-power"></a>MAXIMIZE POWER [METAPSIONIC]</h5>
You can manifest powers to maximum effect.
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> To use this
feat, you must expend your psionic focus.</p>
    <p>You can maximize a power. All variable, numeric effects of a
power modified by this feat are maximized. A maximized power deals
maximum damage, cures the maximum number of hit points, affects the
maximum number of targets, and so on, as appropriate. Saving throws and
opposed checks are not affected, nor are powers without random
variables.</p>
    <p>Augmented powers can be maximized; a maximized augmented power
deals the maximum damage (or cures the maximum hit points, and so on)
of the augmented power.</p>
    <p>An empowered and maximized power gains the separate benefits
of each feat: the maximum result plus one-half the normally rolled
result.</p>
    <p>Using this feat increases the power point cost of the power by
4. The power&rsquo;s total cost cannot exceed your manifester level.</p>
    <h5><a id="mental-leap"></a>MENTAL LEAP [PSIONIC]</h5>
You can make amazing jumps.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span> Str 13, <a href="skillsAll.html#jump" style={{
        "color": "rgb(87, 158, 182)"
      }}>Jump</a>
5 ranks.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> To use this
feat, you must expend your psionic focus. You gain a +10 bonus on a
Jump check. </p>
    <h5><a id="mental-resistance"></a>MENTAL RESISTANCE [GENERAL]</h5>
Your mind is armored against mental intrusion.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span> Base
Will save bonus +2.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> Against
psionic attacks that do not employ an energy type to deal damage you
gain damage reduction 3/-. In addition, when you are hit with <a href="abilitiesAndConditions.html#ability-damaged" style={{
        "color": "rgb(87, 158, 182)"
      }}>ability
damage</a> (but not <a href="abilitiesAndConditions.html#ability-drained" style={{
        "color": "rgb(87, 158, 182)"
      }}>ability drain</a> or ability burn
damage) from a psionic
attack, you take 3 points less than you would normally take.</p>
    <p>The benefit of this feat applies only to psionic powers and
psi-like abilities. This is an exception to the psionics-magic
transparency rule.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Special:</span> You cannot
take or use this feat if you have the ability to use powers (if you
have a power point reserve or psi-like abilities).</p>
    <h5><a id="metamorphic-transfer"></a>METAMORPHIC TRANSFER
[PSIONIC]</h5>
You can gain a supernatural ability of a metamorphed form.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span> Wis 13,
manifester level 5th.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> Each time you
change your form, such as through the <a href="psionicPowersGtoP.html#metamorphosis" style={{
        "color": "rgb(87, 158, 182)"
      }}>metamorphosis</a> power, you gain
one
of the new form&rsquo;s supernatural abilities, if it has any.</p>
    <p>You gain only three uses of the metamorphic ability per day,
even if the creature into which you metamorph has a higher limit on
uses (You are still subject to other restrictions on the use of the
ability.) The save DC to resist a supernatural ability gained through
Metamorphic Transfer (if it is an attack) is 10 + your Cha modifier +
1/2 your Hit Dice.
    </p>
    <p>No matter how many times you manifest the metamorphosis power
on a given day, you can gain only a total of three supernatural ability
transfers per day.
    </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Normal:</span> You cannot use
the supernatural abilities of creatures whose form you assume.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Special:</span> You can gain
this feat multiple times. Each time, you can gain one additional
supernatural ability.</p>
    <h5><a id="mind-over-body"></a>MIND OVER BODY [GENERAL]</h5>
Your ability damage heals more rapidly.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span> Con 13.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> You heal <a href="abilitiesAndConditions.html#ability-damaged" style={{
        "color": "rgb(87, 158, 182)"
      }}>ability damage</a> and ability burn
damage more quickly than normal. You
heal a number of ability points per day equal to 1 + your Constitution
bonus. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Normal:</span> You heal
ability damage and ability burn damage at a rate of 1 point per day.</p>
    <h5><a id="narrow-mind"></a>NARROW MIND [PSIONIC]</h5>
Your ability to concentrate is as keen as an arrowhead, allowing you to
gain your psionic focus even in the most turbulent situations.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span> Wis 13.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> You gain a +4
bonus on <a href="psionicSkills.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a> checks you make to
become psionically focused.</p>
    <h5><a id="open-minded"></a>OPEN MINDED [GENERAL]</h5>
You are naturally able to reroute your memory, mind, and skill
expertise.
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> You
immediately gain an extra 5 skill points. You spend these skill points
as normal. If you spend them on a cross-class skills they count as 1/2
ranks. You cannot exceed the normal maximum ranks for your level in any
skill. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Special:</span> You can gain
this feat multiple times. Each time, you immediately gain another 5
skill points.</p>
    <h5><a id="opportunity-power"></a>OPPORTUNITY POWER
[METAPSIONIC]</h5>
You can make power-enhanced attacks of opportunity.
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> To use this
feat, you must expend your psionic focus. When you make an attack of
opportunity, you can use any power you know with a range of touch, if
you have at least one hand free.</p>
    <p>Manifesting this power is an immediate action.</p>
    <p>You cannot use this feat with a touch power whose manifesting
time is longer than 1 full-round action.</p>
    <p>Using this feat increases the power point cost of the power by
6. The power&rsquo;s total cost cannot exceed your manifester level.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Normal:</span> Attacks of
opportunity can be made only with melee weapons.</p>
    <h5><a id="overchannel"></a>OVERCHANNEL [PSIONIC]</h5>
You burn your life force to strengthen your powers.
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> While
manifesting a power, you can increase your effective manifester level
by one, but in so doing you take 1d8 points of damage. At 8th level,
you can choose to increase your effective manifester level by two, but
you take 3d8 points of damage. At 15th level, you can increase your
effective manifester level by three, but you take 5d8 points of damage.</p>
    <p>The effective increase in manifester level increases the
number of power points you can expend on a single power manifestation,
as well as increasing all manifester level-dependent effects, such as
range, duration, and overcoming power resistance.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Normal:</span> Your
manifester level is equal to your total levels in classes that manifest
powers.</p>
    <h5><a id="power-penetration"></a>POWER PENETRATION [PSIONIC]</h5>
Your powers are especially potent, breaking through power resistance
more readily than normal.
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> To use this
feat, you must expend your psionic focus. You get a +4 bonus on
manifester level checks made to overcome a creature&rsquo;s power
resistance.</p>
    <h5><a id="power-specialization"></a>POWER SPECIALIZATION
[PSIONIC]</h5>
You deal more damage with your powers.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisites:</span> <a href="featsAll.html#weapon-focus" style={{
        "color": "rgb(87, 158, 182)"
      }}>Weapon
Focus</a> (ray), manifester level 4th.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> With rays and
ranged touch attack powers that deal damage, you deal an extra 2 points
of damage. If you expend your psionic focus when you manifest a ray or
a ranged touch attack power that deals damage, you add your key ability
bonus to the damage (instead of adding 2).</p>
    <h5><a id="psicrystal-affinity"></a>PSICRYSTAL AFFINITY
[PSIONIC]</h5>
You have created a psicrystal.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisites:</span>
Manifester level 1st.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> This feat
allows you to gain a <a style={{
        "color": "#579eb6"
      }} href="psionicPsion.html#psicrystals">psicrystal</a>.</p>
    <h5><a id="psicrystal-containment"></a>PSICRYSTAL CONTAINMENT
[PSIONIC]</h5>
Your psicrystal has advanced enough that it can hold a psionic focus
that you store within it.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisites:</span> <a href="#psicrystal-affinity" style={{
        "color": "rgb(87, 158, 182)"
      }}>Psicrystal
Affinity</a>, manifester level 3rd.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> You can spend
a full-round action attempting to psionically focus your <a style={{
        "color": "#579eb6"
      }} href="psionicPsion.html#psicrystals">psicrystal</a>. At
any time when you need to expend your psionic focus, you can expend
your psicrystal&rsquo;s psionic focus instead, as long as the crystal
is
within 5 feet of you. Psionically focusing your psicrystal works just
like focusing yourself. The psicrystal cannot focus itself - only the
owner can spend the time to focus the crystal.</p>
    <h5><a id="psionic-affinity"></a>PSIONIC AFFINITY [GENERAL]</h5>
You have a knack for psionic endeavors.
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> You get a +2
bonus on all <a href="psionicSkills.html#psicraft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Psicraft</a> checks and <a href="psionicSkills.html#use-psionic-device" style={{
        "color": "rgb(87, 158, 182)"
      }}>Use Psionic Device </a>checks.</p>
    <h5><a id="psionic-body"></a>PSIONIC BODY [PSIONIC]</h5>
Your mind reinforces your body.
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> When you take
this feat, you gain 2 hit points for each psionic feat you have
(including this one). Whenever you take a new psionic feat, you gain 2
more hit points. </p>
    <h5><a id="psionic-charge"></a>PSIONIC CHARGE [PSIONIC]</h5>
You can charge in a crooked line.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span> Dex 13, <a href="#speed-of-thought" style={{
        "color": "rgb(87, 158, 182)"
      }}>Speed
of Thought</a>.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> To use this
feat, you must expend your psionic focus. When you charge, you can make
one turn of up to 90 degrees during your movement. All other
restrictions on charges still apply; for instance, you cannot pass
through a square that blocks or slows movement, or that contains a
creature. You must have line of sight to the opponent at the start of
your turn.</p>
    <h5><a id="psionic-dodge"></a>PSIONIC DODGE [PSIONIC]</h5>
You are proficient at dodging blows.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span> Dex 13, <a href="featsAll.html#dodge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Dodge</a>.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> You must be
psionically focused to use this feat. You receive a +1 dodge bonus to
your Armor Class. This bonus stacks with the bonus from the Dodge feat
(but only applies on attacks made by the opponent you have designated).</p>
    <h5><a id="psionic-endowment"></a>PSIONIC ENDOWMENT [PSIONIC]</h5>
You can endow your manifestations with more concentrated focus.
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> To use this
feat, you must expend your psionic focus. You add 1 to the save DC of a
power you manifest.</p>
    <h5><a id="psionic-fist"></a>PSIONIC FIST [PSIONIC]</h5>
You can charge your unarmed strike or natural weapon with additional
damage potential.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span> Str 13.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> To use this
feat, you must expend your psionic focus. Your unarmed strike or attack
with a natural weapon deals an extra 2d6 points of damage.</p>
    <p>You must decide whether or not to use this feat prior to
making an attack. If your attack misses, you still expend your psionic
focus.</p>
    <h5><a id="psionic-hole"></a>PSIONIC HOLE [GENERAL]</h5>
You are anathema to psionic creatures and characters.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span> Con 15.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> When a foe
strikes you in melee combat, the foe immediately loses its psionic
focus, if any. Also, if you are the target of a power, the manifester
of the power must spend an additional number of power points equal to
your Wisdom bonus, or the power fails (all the power points spent on
the power are still lost). This extra cost does not count toward the
maximum power points a manifester can spend on a single power.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Special:</span> You cannot
take or use this feat if you have the ability to use powers (if you
have a power point reserve or psi-like abilities).</p>
    <h5><a id="psionic-meditation"></a>PSIONIC MEDITATION [PSIONIC]</h5>
You can focus your mind faster than normal, even under duress.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span> Wis 13, <a href="psionicSkills.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a> 7 ranks.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> You can take
a move action to become psionically focused.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Normal:</span> A character
without this feat must take a full-round action to become psionically
focused.</p>
    <h5><a id="psionic-shot"></a>PSIONIC SHOT [PSIONIC]</h5>
You can charge your ranged attacks with additional damage potential.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span> <a href="featsAll.html#point-blank-shot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Point
Blank Shot</a>.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> To use this
feat, you must expend your psionic focus. Your ranged attack deals +2d6
points of damage. You must decide whether or not to use this feat prior
to making an attack. If your attack misses, you still expend your
psionic focus.</p>
    <h5><a id="psionic-talent"></a>PSIONIC TALENT [PSIONIC]</h5>
You gain additional power points to supplement those you already had.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span> Having a
power point reserve.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> When you take
this feat for the first time, you gain 2 power points.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Special:</span> You can take
this feat multiple times. Each time you take the feat after the first
time, the number of power points you gain increases by 1.</p>
    <h5><a id="psionic-weapon"></a>PSIONIC WEAPON [PSIONIC]</h5>
You can charge your melee weapon with additional damage potential.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span> Str 13.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> To use this
feat, you must expend your psionic focus.</p>
    <p>Your attack with a melee weapon deals an extra 2d6 points of
damage. You must decide whether or not to use this feat prior to making
an attack. If your attack misses, you still expend your psionic focus.</p>
    <h5><a id="quicken-power"></a>QUICKEN POWER [METAPSIONIC]</h5>
You can manifest a power with a moment&rsquo;s thought.
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> To use this
feat, you must expend your psionic focus. You can quicken a power. You
can perform another action, even manifest another power, in the same
round that you manifest a quickened power. You can manifest only one
quickened power per round. A power whose manifesting time is longer
than 1 round cannot be quickened.</p>
    <p>Using this feat increases the power point cost of the power by
6. The power&rsquo;s total cost cannot exceed your manifester level.</p>
    <p>Manifesting a quickened power does not provoke attacks of
opportunity.</p>
    <h5><a id="rapid-matabolism"></a>RAPID METABOLISM [GENERAL]</h5>
Your wounds heal rapidly.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span> Con 13.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> You naturally
heal a number of hit points per day equal to the standard healing rate
+ double your Constitution&nbsp; bonus. You heal even if you do not
rest. This healing replaces your normal natural healing. If you are
tended successfully by someone with the Heal skill, you instead regain
double the normal amount of hit points + double your Constitution bonus.</p>
    <h5><a id="reckless-offense"></a>RECKLESS OFFENSE [GENERAL]</h5>
You can shift your focus from defense to offense.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span> Base
attack bonus +1.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> When you use
the attack action or full attack action in melee, you can take a
penalty of -4 to your Armor Class and add a +2 bonus on your melee
attack roll. The bonus on attack rolls and penalty to Armor Class last
until the beginning of your next turn.</p>
    <h5><a id="return-shot"></a>RETURN SHOT [PSIONIC]</h5>
You can return incoming arrows, as well as crossbow bolts, spears, and
other projectile or thrown weapons.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span> <a href="featsAll.html#point-blank-shot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Point
Blank Shot</a>, <a href="#psionic-shot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Psionic Shot</a>, <a href="#fell-shot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Fell Shot</a>,
base attack bonus +3.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> To use this
feat, you must expend your psionic focus and have at least one hand
free. Once per round when you would normally be hit by a projectile or
a thrown weapon no more than one size category larger than your size,
you can deflect the attack so that you take no damage from it. The
attack is deflected back at your attacker, using the attack bonus of
the original attack on you. You must be aware of the attack and not
flat-footed. Attempting to return a shot is a free action.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Special:</span> If you also
have the <a href="featsAll.html#deflect-arrows" style={{
        "color": "rgb(87, 158, 182)"
      }}>Deflect Arrows feat</a>, the
deflected attack is made with the
original attack bonus plus your Dexterity bonus.</p>
    <h5><a id="scribe-tattoo"></a>SCRIBE TATTOO [ITEM CREATION]</h5>
You can create psionic tattoos, which store powers within their designs.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span>
Manifester level 3rd.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> You can
create a psionic tattoo of any power of 3rd level or lower that you
know and that targets one or more creatures. Scribing a psionic tattoo
takes one day. When you create a psionic tattoo, you set the manifester
level. The manifester level must be sufficient to manifest the power in
question and no higher than your own level. The base price of a psionic
tattoo is its power level x its manifester level x 50 gp. To scribe a
tattoo, you must spend 1/25 of this base price in XP and use up raw
materials (special inks, masterwork needles, and so on) costing
one-half of this base price.</p>
    <p>When you create a psionic tattoo, you make any choices that
you would normally make when manifesting the power.</p>
    <p>When its wearer physically activates the tattoo, the wearer is
the target of the power.</p>
    <p>Any psionic tattoo that stores a power with an XP cost also
carries a commensurate cost. In addition to the costs derived from the
base price, you must pay the XP when creating the tattoo.</p>
    <h5><a id="sidestep-charge"></a>SIDESTEP CHARGE [GENERAL]</h5>
You are skilled at dodging past charging opponents and taking advantage
when they miss.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span> Dex 13, <a href="featsAll.html#dodge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Dodge</a>.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> You get a +4
dodge bonus to Armor Class against charge attacks. If a charging
opponent fails to make a successful attack against you, you gain an
immediate attack of opportunity. This feat does not grant you more
attacks of opportunity than you are normally allowed in a round. If you
are flat-footed or otherwise denied your Dexterity bonus to Armor
Class, you do not gain the benefit of this feat.</p>
    <h5><a id="speed-of-thought"></a>SPEED OF THOUGHT [PSIONIC]</h5>
The energy of your mind energizes the alacrity of your body.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span> Wis 13.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> As long as
you are psionically focused and not wearing heavy armor, you gain an
insight bonus to your speed of 10 feet.</p>
    <h5><a id="split-psionic-ray"></a>SPLIT PSIONIC RAY
[METAPSIONIC]</h5>
You can affect two targets with a single ray.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span> Any
other metapsionic feat.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> To use this
feat, you must expend your psionic focus. You can split psionic rays
you manifest. The split ray affects any two targets that are both
within the power&rsquo;s range and within 30 feet of each other. If the
ray
deals damage, each target takes as much damage as a single target would
take.</p>
    <p>Using this feat increases the power point cost of the power by
2.</p>
    <h5><a id="stand-still"></a>STAND STILL [GENERAL]</h5>
You can prevent foes from fleeing or closing.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span> Str 13.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> When a
foe&rsquo;s
movement out of a square you threaten grants you an attack of
opportunity, you can give up that attack and instead attempt to stop
your foe in his tracks. Make your attack of opportunity normally. If
you hit your foe, he must succeed on a Reflex save against a DC of 10 +
your damage roll (the opponent does not actually take damage), or
immediately halt as if he had used up his move actions for the round.</p>
    <p>Since you use the Stand Still feat in place of your attack of
opportunity, you can do so only a number of times per round equal to
the number of times per round you could make an attack of opportunity
(normally just one).</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Normal:</span> Attacks of
opportunity cannot halt your foes in their tracks.</p>
    <h5><a id="talented"></a>TALENTED [PSIONIC]</h5>
You can overchannel powers with less cost to yourself.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span> <a href="#overchannel" style={{
        "color": "rgb(87, 158, 182)"
      }}>Overchannel</a>.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> To use this
feat, you must expend your psionic focus. When manifesting a power of
3rd level or lower, you do not take damage from overchanneling.</p>
    <h5><a id="twin-power"></a>TWIN POWER [METAPSIONIC]</h5>
You can manifest a power simultaneously with another power just like it.
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> To use this
feat, you must expend your psionic focus. You can twin a power.
Manifesting a power altered by this feat causes the power to take
effect twice on the area or target, as if you were simultaneously
manifesting the same power two times on the same location or target.
Any variables in the power (such as duration, number of targets, and so
on) are the same for both of the resulting powers. The target
experiences all the effects of both powers individually and receives a
saving throw (if applicable) for each. In some cases, such as a twinned
psionic charm, failing both saving throws results in redundant effects
(although, in this example, any ally of the target would have to
succeed on two dispel attempts to free the target from the charm
effect).</p>
    <p>Using this feat increases the power point cost of the power by
6. The power&rsquo;s total cost cannot exceed your manifester level.</p>
    <h5><a id="unavoidable-strike"></a>UNAVOIDABLE STRIKE [PSIONIC]</h5>
You can make an unarmed strike or use a natural weapon against your foe
as if delivering a touch attack.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span> Str 13, <a href="#psionic-fist" style={{
        "color": "rgb(87, 158, 182)"
      }}>Psionic
Fist</a>, base attack bonus +5.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> To use this
feat, you must expend your psionic focus. You can resolve your unarmed
strike or attack with a natural weapon as a touch attack.</p>
    <p>You must decide whether or not to use this feat prior to
making an attack. If your attack misses, you still expend your psionic
focus.</p>
    <h5><a id="unconditional-power"></a>UNCONDITIONAL POWER
[METAPSIONIC]</h5>
Disabling conditions do not hold you back.
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> To use this
feat, you must expend your psionic focus. Your mental strength is
enough to overcome some otherwise disabling conditions. You can
manifest an unconditional power when you are <a href="abilitiesAndConditions.html#dazed" style={{
        "color": "rgb(87, 158, 182)"
      }}>dazed</a>, <a href="abilitiesAndConditions.html#confused" style={{
        "color": "rgb(87, 158, 182)"
      }}>confused</a>, <a href="abilitiesAndConditions.html#nauseated" style={{
        "color": "rgb(87, 158, 182)"
      }}>nauseated</a>, or <a href="abilitiesAndConditions.html#stunned" style={{
        "color": "rgb(87, 158, 182)"
      }}>stunned</a>.</p>
    <p>Only personal powers and powers that affect your person can be
manifested as unconditional powers.</p>
    <p>Using this feat increases the power point cost of the power by
8. The power&rsquo;s total cost cannot exceed your manifester level.</p>
    <h5><a id="up-the-walls"></a>UP THE WALLS [PSIONIC]</h5>
You can run on walls for brief distances.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span> Wis 13.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> While you are
psionically focused, you can take part of one of your move actions to
traverse a wall or other relatively smooth vertical surface if you
begin and end your move on a horizontal surface. The height you can
achieve on the wall is limited only by this movement restriction. If
you do not end your move on a horizontal surface, you fall prone,
taking falling damage as appropriate for your distance above the
ground. Treat the wall as a normal floor for the purpose of measuring
your movement. Passing from floor to wall or wall to floor costs no
movement; you can change surfaces freely. Opponents on the ground can
make attacks of opportunity as you move up the wall.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Special:</span> You can take
other move actions in conjunction with moving along a wall. For
instance, the <a href="featsAll.html#spring-attack" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spring Attack</a> feat allows you to
make an attack from the
wall against a foe standing on the ground who is within the area you
threaten; however, if you are somehow prevented from completing your
move, you fall. Likewise, you could <a href="skillsAll.html#tumble" style={{
        "color": "rgb(87, 158, 182)"
      }}>tumble</a> along the wall to avoid
attacks of opportunity.</p>
    <h5><a id="widen-power"></a>WIDEN POWER [METAPSIONIC]</h5>
You can increase the area of your powers.
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> To use this
feat, you must expend your psionic focus. You can alter a burst,
emanation, line, or spread-shaped power to increase its area. (Powers
that do not have an area of one of these four sorts are not affected by
this feat.) Any numeric measurements of the power&rsquo;s area increase
by
100%.</p>
    <p>Using this feat increases the power point cost of the power by
4. The power&rsquo;s total cost cannot exceed your manifester level.</p>
    <h5><a id="wild-talent"></a>WILD TALENT [GENERAL]</h5>
Your mind wakes to a previously unrealized talent for psionics.
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> Your latent
power of psionics flares to life,
conferring upon you the designation of a psionic character. As a
psionic character, you gain a reserve of 2 power points and can take
psionic feats, metapsionic feats, and psionic item creation feats. You
do not, however, gain the ability to manifest powers simply by virtue
of having this feat.</p>
    <h5><a id="wounding-attack"></a>WOUNDING ATTACK [PSIONIC]</h5>
Your vicious attacks wound your foe.
    <p><span style={{
        "fontWeight": "bold"
      }}>Prerequisite:</span> Base
attack bonus +8.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> To use this
feat, you must expend your psionic focus. You can make an attack with
such vicious force that you wound your opponent. A wound deals 1 point
of Constitution damage to your foe in addition to the usual damage
dealt.</p>
    <p>You must decide whether or not to use this feat prior to
making an attack. If your attack misses, you still expend your psionic
focus.</p>
    <p className="initial">
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      